import { IResponseData } from "@/models/common";
import { IDicomImage } from "@/models/file";
import { IStudyThumbnail } from "@/models/study";
import $API from "@/services";
import { AxiosResponse } from "axios";

const dicom = {
  getStudyKey : async (fileKey:string)=>{
    return await $API.FileService.getStudyKey(fileKey);
  },
  getDicomImage : async (studyKeys: Array<Number>) => {
    return await Promise.all(
      studyKeys.map(async (k) => {
        const res: AxiosResponse<
          IResponseData<IStudyThumbnail>
        > = await $API.StudyService.getBase64StudyThumbnail(k.toString());
        const {
          data: { ResultData },
        } = res;
        if (!Array.isArray(ResultData)) {
          return {
            image: "data:image/jpeg;base64," + ResultData.ImageDataBase64,
            studyKey: k,
          } as IDicomImage;
        } else return { image: null, studyKey: null };
      })
    );
  }
}

export default dicom;