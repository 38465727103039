<i18n>
{
  "en": {
    "removeHeader" : "Are you sure to delete?",
    "remove": "Remove"
  },
  "ja": {
    "removeHeader" : "해당 파일을 삭제 하시겠습니까?",
    "remove": "삭제"
  },
  "ko":{
    "removeHeader" : "해당 파일을 삭제 하시겠습니까?",
    "remove": "삭제",
    "desc": {
      "a":"Dicom영상은 .zip파일로 압축 후 upload",
      "b":"아이폰에서 촬영한 영상이 보이지 않을 때", 
      "c":"여기",
      "d":"를 눌러",
      "e":"[미디어 캡처하기]",
      "f":"를 참고하세요"
    }
  }
}
</i18n>
<template>
  <section class="file-manager__list">
    <div class="desc">
      <small v-if="isSecondOpinion">{{ l("desc.a") }}}</small>
      <small v-if="!isPx">
        아이폰에서 촬영한 영상이 보이지 않을 때
        <a
          class="-link -royalBlue"
          target="_blank"
          href="https://support.apple.com/ko-kr/HT207022"
          style="font-weight: 600"
          >여기</a
        >를 눌러 <b>[미디어 캡처하기]</b>를 참고하세요</small
      >
    </div>

    <DataTable
      :value="state.list"
      @row-select="onSelectedRow"
      dataKey="FileKey"
      v-model:selection="state.select"
      selectionMode="single"
      class="data-table"
    >
      <Column field="Image" header="">
        <template #body
          ><img class="file-image" src="/images/new/file.svg"
        /></template>
      </Column>
      <Column field="FileInfo" header="">
        <template #body="slotProps">
          <div class="col-body file-info">
            <span> {{ slotProps.data.Title }} </span
            ><span>{{ slotProps.data.DataType }}</span>
          </div>
        </template>
      </Column>
      <Column field="FileKey" header="">
        <template #body="slotProps">
          <Button
            class="file-remove"
            v-if="!slotProps.data.RefKeyValue3 || isLive"
            @click="
              () => {
                openModal();
              }
            "
          >
            <i class="pi pi-trash" />
          </Button>
          <Dialog
            :header="l('remove')"
            v-model:visible="displayModal"
            :style="{ width1: '50vw' }"
            :modal="true"
          >
            <div class="p-py-3">
              {{ l("removeHeader") }}
            </div>
            <template #footer>
              <Button
                :label="t('No')"
                @click="closeModal()"
                class="p-button-text"
              />
              <Button
                :label="t('Yes')"
                class="p-button-danger"
                icon="pi pi-check"
                @click="deleteFile(slotProps.data.FileKey)"
                autofocus
              />
            </template>
          </Dialog>
        </template>
      </Column>
    </DataTable>
    <FileViewerDialog
      v-if="state.visibleFull"
      :file="state.select"
      @close="FileViewerDialogClose"
    >
      <template v-if="state.isPx" #title> 처방전 보기 </template>
    </FileViewerDialog>
  </section>
</template>
<script lang="ts">
import { IFileListItem } from "@/models/file";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import $API from "@/services";
import { AxiosResponse } from "node_modules/axios";
import { IResponseData, StatusTexts } from "@/models/common";
import Dialog from "primevue/dialog";
import { useI18n } from "vue-i18n";
import { IRequest, RequestKindTypes, RequestStatuses } from "@/models/request";
import { useStore } from "vuex";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import FileViewerDialog from "@/components/file/FileViewerDialog.vue";
import { DataType } from "@/services/file";
import { onBeforeRouteLeave } from "vue-router";

export default defineComponent({
  name: "File List",
  props: {
    list: {
      type: Array as PropType<Array<IFileListItem>>,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    requestKind: {
      type: String as PropType<RequestKindTypes>,
      required: false,
    },
    isPx: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const displayModal = ref(false);
    const store = useStore();
    const { t: l } = useI18n();
    const { t } = useI18n({ useScope: "global" });

    const state = reactive({
      id: props.id,
      list: props.isPx
        ? props.list
        : props.list?.filter(
            (f) => f.FileStatus == 0 && f.PrescriptionFlag != 1
          ),
      select: {} as IFileListItem,
      requestStatus: null as RequestStatuses | null,
      isLoading: true,
      visibleFull: false,
      isPx: props.isPx,
    });

    const columns = ref([
      // { field: "FileKey", header: "FileKey" },
      { field: "Title", header: t("FileTitle") },
      { field: "DataType", header: t("DataType") },
      // { field: "FileType", header: t("FileType") },
    ]);

    /**
     * file 선택 event
     */
    const onSelectedRow = async () => {
      // emit("onSelectedRow", state.select);
      try {
        if (state.select.DataType === DataType.DICOM)
          await getStudyKey(state.select.FileKey.toString());

        state.visibleFull = true;
      } catch (e) {
        console.error(e);
      }
    };

    const getStudyKey = async (fileKey: string) => {
      return await $API.FileService.getStudyKey(fileKey);
    };

    /**
     * file 삭제 event
     */
    const deleteFile = async (fileKey: number) => {
      const res: AxiosResponse = await $API.FileService.deleteFile(fileKey);
      if (res.statusText === StatusTexts.OK || res.status === 200) {
        emit("deletedFile");
      }
    };

    const openModal = () => {
      displayModal.value = true;
    };
    const closeModal = () => {
      displayModal.value = false;
    };

    const isLive = computed(() => {
      return (
        !state.id ||
        state.requestStatus === RequestStatuses.Preparing ||
        state.requestStatus === RequestStatuses.Requested
      );
    });
    const getRequest = async () => {
      if (!state.id) {
        return;
      }

      const res: AxiosResponse<IResponseData<IRequest>> =
        await $API.RequestService.getRequest(Number.parseInt(state.id));
      const {
        data: { ResultData },
      } = res;
      if (!Array.isArray(ResultData)) {
        state.requestStatus = ResultData.RequestStatus ?? null;
      }
    };

    const FileViewerDialogClose = () => {
      state.visibleFull = false;
      const highlight$ = document.querySelector(
        ".p-selectable-row.p-highlight"
      );

      if (highlight$) highlight$.classList.remove("p-highlight");
    };

    onBeforeRouteLeave((from, to, next) => {
      if (state.visibleFull) {
        state.visibleFull = false;
        next(false);
      } else next();
    });

    onMounted(async () => {
      try {
        state.isLoading = true;
        await getRequest();
      } catch (e) {
        console.error(e);
      } finally {
        state.isLoading = false;
        store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    });

    const isSecondOpinion = computed(
      () => props?.requestKind === RequestKindTypes.SecondOpinion
    );

    return {
      state,
      columns,
      onSelectedRow,
      deleteFile,
      displayModal,
      openModal,
      closeModal,
      l,
      t,
      isLive,
      isSecondOpinion,
      FileViewerDialogClose,
    };
  },

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    FileViewerDialog,
  },
});
</script>
<style lang="scss">
@import "@/styles/mixin";

.file-manager__list {
  @include mobile {
    .p-datatable .p-datatable-tbody tr td {
      padding: 0.33rem 1rem;
    }
  }
}
.file-manager {
  @include mobile {
    .p-card .p-card-body {
      padding: 0.5rem;
    }
  }
}

.data-table.p-datatable {
  margin-bottom: 24px;
  thead {
    display: none;
  }

  tbody.p-datatable-tbody > tr {
    background: #ffffff;
    border: 1px solid $Grey1;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    display: flex;
    padding: 14px 20px;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
    > td {
      border: none;
      padding: 0;
      display: block;
      width: auto !important;
      &:last-child {
        position: absolute;
        right: 20px;
      }

      .p-column-title {
        margin-right: 10px;
        min-width: 73px;
        max-width: 83px;
        @include mobile {
          display: none;
        }
      }
    }
    .file-image {
      width: 32px;
      height: 38px;
      margin-right: 20px;
    }
    .file-info {
      display: flex;
      flex-direction: column;
    }
  }
}
.col-body {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
small {
  display: block;
  text-align: right;
  b {
    color: $RoyalBlue;
  }
  @include mobile {
    font-size: 9px;
  }
}
button.file-remove {
  padding: 0;
  width: 30px;
  height: 30px;
  background: transparent;
  border: 1px solid $Grey1;
  border-radius: 6px;
  color: $Grey3;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $White;
  }
}
</style>