
import { IDicomImage, IFileListItem } from "@/models/file";
import {
  defineComponent,
  onUpdated,
  PropType,
  reactive,
  Ref,
  ref,
  watch,
} from "vue";
import $API from "@/services";
import { DataType, FileType } from "@/services/file";
import Sidebar from "primevue/sidebar";
import { AxiosResponse } from "axios";
import { IResponseData } from "@/models/common";
import { onBeforeRouteLeave } from "vue-router";
import Dialog from "primevue/dialog";
import Dicom from "@/utils/Dicom";

export default defineComponent({
  name: "File Viewer",
  props: {
    select: {
      type: Object as PropType<IFileListItem>,
      required: false,
    },
    isSidebar: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const state = reactive({
      select: props.select as IFileListItem,
      visibleFull: false,
      images: [] as Array<IDicomImage>,
      isLoading: false,
      isSlidebar: !!props.isSidebar,
    });

    const srcDicomViewer = ref("");
    const titleDicomViewer: Ref<String> = ref("");
    const showDicomViewer = ref(false);

    const video$: Ref<HTMLVideoElement | null> = ref(null);

    /**
     * 뒤로가기 시 전체화면이면 전체화면만 종료, 아니면 뒤로가기
     */
    onBeforeRouteLeave((to, from, next) => {
      if (state.visibleFull) {
        state.visibleFull = false;
        next(false);
      } else if (showDicomViewer.value) {
        showDicomViewer.value = false;
        next(false);
      } else next();
    });

    onUpdated(async () => {
      if (props.select?.FileKey === state.select.FileKey) return;
      state.select = props.select as IFileListItem;
      if (state.select.DataType === DataType.VIDEO) {
        video$.value?.load();
        video$.value?.play();
      }
    });

    const viewDicom = async () => {
      let studyKeys: Array<Number> | null = null;
      const res: AxiosResponse<IResponseData<Number>> = await Dicom.getStudyKey(
        state.select.FileKey.toString()
      );
      const {
        data: { ResultData },
      } = res;
      if (Array.isArray(ResultData)) studyKeys = ResultData;

      if (Array.isArray(studyKeys)) {
        state.images = await Dicom.getDicomImage(studyKeys);
      }
    };

    /**
     * 선택한 파일이 dicom이면 다이콤뷰어 open
     */
    const displayFile = async () => {
      if (state.select.DataType === DataType.DICOM) {
        await viewDicom();
      }
    };

    /**
     * pdf iframe 로드 후 height 조절
     */
    const pdfIframeLoad = () => {
      setTimeout(() => {
        const pdfIframe = document.querySelector(
          "#pdfIframe"
        ) as HTMLIFrameElement;
        if (!pdfIframe) return;
        const pdfIframeContent = pdfIframe.contentWindow;
        if (!pdfIframeContent) return;
        const page = pdfIframeContent.document.querySelector(
          ".page"
        ) as HTMLDivElement;
        if (!page) return;
        pdfIframe.style.height = page.scrollHeight + 100 + "px";
      }, 1000);
    };

    watch(
      () => state.select,
      async () => {
        await displayFile();
      }
    );
    displayFile();

    const dicomViewerPath = `${$API.BaseURL}/DicomViewer?studyKey=`;

    return {
      state,
      DataType,
      FileType,
      dicomViewerPath,
      video$,
      srcDicomViewer,
      titleDicomViewer,
      showDicomViewer,
      pdfIframeLoad,
    };
  },
  methods: {
    /**
     * image인 경우 path
     */
    getImagePath(filePath: string) {
      return $API.FileService.getFilePath(filePath);
    },
    /**
     * pdf인 경우 path, pdf view로 open
     */
    getPdfPath(filePath: string) {
      return $API.FileService.getPdfPath(filePath);
    },
    /**
     * dicom인 경우 path
     */
    openDicomViewer(fileItem: IFileListItem, studyKey: number) {
      this.srcDicomViewer = this.dicomViewerPath + studyKey + "&opts=noclose";
      this.titleDicomViewer = fileItem.Title;

      this.showDicomViewer = true;
    },
  },

  components: {
    Sidebar,
    Dialog,
  },
});
