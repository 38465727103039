
import { IFileListItem } from "@/models/file";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import $API from "@/services";
import { AxiosResponse } from "node_modules/axios";
import { IResponseData, StatusTexts } from "@/models/common";
import Dialog from "primevue/dialog";
import { useI18n } from "vue-i18n";
import { IRequest, RequestKindTypes, RequestStatuses } from "@/models/request";
import { useStore } from "vuex";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import FileViewerDialog from "@/components/file/FileViewerDialog.vue";
import { DataType } from "@/services/file";
import { onBeforeRouteLeave } from "vue-router";

export default defineComponent({
  name: "File List",
  props: {
    list: {
      type: Array as PropType<Array<IFileListItem>>,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    requestKind: {
      type: String as PropType<RequestKindTypes>,
      required: false,
    },
    isPx: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const displayModal = ref(false);
    const store = useStore();
    const { t: l } = useI18n();
    const { t } = useI18n({ useScope: "global" });

    const state = reactive({
      id: props.id,
      list: props.isPx
        ? props.list
        : props.list?.filter(
            (f) => f.FileStatus == 0 && f.PrescriptionFlag != 1
          ),
      select: {} as IFileListItem,
      requestStatus: null as RequestStatuses | null,
      isLoading: true,
      visibleFull: false,
      isPx: props.isPx,
    });

    const columns = ref([
      // { field: "FileKey", header: "FileKey" },
      { field: "Title", header: t("FileTitle") },
      { field: "DataType", header: t("DataType") },
      // { field: "FileType", header: t("FileType") },
    ]);

    /**
     * file 선택 event
     */
    const onSelectedRow = async () => {
      // emit("onSelectedRow", state.select);
      try {
        if (state.select.DataType === DataType.DICOM)
          await getStudyKey(state.select.FileKey.toString());

        state.visibleFull = true;
      } catch (e) {
        console.error(e);
      }
    };

    const getStudyKey = async (fileKey: string) => {
      return await $API.FileService.getStudyKey(fileKey);
    };

    /**
     * file 삭제 event
     */
    const deleteFile = async (fileKey: number) => {
      const res: AxiosResponse = await $API.FileService.deleteFile(fileKey);
      if (res.statusText === StatusTexts.OK || res.status === 200) {
        emit("deletedFile");
      }
    };

    const openModal = () => {
      displayModal.value = true;
    };
    const closeModal = () => {
      displayModal.value = false;
    };

    const isLive = computed(() => {
      return (
        !state.id ||
        state.requestStatus === RequestStatuses.Preparing ||
        state.requestStatus === RequestStatuses.Requested
      );
    });
    const getRequest = async () => {
      if (!state.id) {
        return;
      }

      const res: AxiosResponse<IResponseData<IRequest>> =
        await $API.RequestService.getRequest(Number.parseInt(state.id));
      const {
        data: { ResultData },
      } = res;
      if (!Array.isArray(ResultData)) {
        state.requestStatus = ResultData.RequestStatus ?? null;
      }
    };

    const FileViewerDialogClose = () => {
      state.visibleFull = false;
      const highlight$ = document.querySelector(
        ".p-selectable-row.p-highlight"
      );

      if (highlight$) highlight$.classList.remove("p-highlight");
    };

    onBeforeRouteLeave((from, to, next) => {
      if (state.visibleFull) {
        state.visibleFull = false;
        next(false);
      } else next();
    });

    onMounted(async () => {
      try {
        state.isLoading = true;
        await getRequest();
      } catch (e) {
        console.error(e);
      } finally {
        state.isLoading = false;
        store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    });

    const isSecondOpinion = computed(
      () => props?.requestKind === RequestKindTypes.SecondOpinion
    );

    return {
      state,
      columns,
      onSelectedRow,
      deleteFile,
      displayModal,
      openModal,
      closeModal,
      l,
      t,
      isLive,
      isSecondOpinion,
      FileViewerDialogClose,
    };
  },

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    FileViewerDialog,
  },
});
