
import { computed, defineComponent, PropType, reactive, watch } from "vue";
import Sidebar from "@/components/primevueCustom/Sidebar.vue";
import FileViewer from "@/components/file/FileViewer.vue";
import { IFileListItem } from "@/models/file";
import { DataType, FileType } from "@/services/file";
import Button from "primevue/button";
import $API from "@/services";

export default defineComponent({
  name: "File Viewer Dialog",
  emits: ["close"],
  props: {
    file: {
      type: Object as PropType<IFileListItem>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      file: props.file,
      visibleFull: true,
    });

    watch(
      () => state.visibleFull,
      (n) => {
        if (!n) emit("close", n);
      }
    );

    const downloadPath = computed(() => {
      const fType = state.file.FileType;
      const dType = state.file.DataType;
      const filePath = state.file.FilePath.valueOf();
      let downPath = "";

      if (
        fType === FileType.PDF ||
        dType === DataType.IMAGE ||
        dType === DataType.VIDEO
      )
        downPath = $API.FileService.getFileDownLoadPath(filePath);
      else return false;
      return downPath;
    });

    const download = async () => {
      const path = downloadPath.value;
      if (!path) return;

      console.log(path);

      await $API.FileService.downloadFile(path, state.file.Title.valueOf());
    };

    return { state, downloadPath, download };
  },
  components: {
    Sidebar,
    FileViewer,
    Button,
  },
});
